
export const Apis = {
    login: "/admin_login",
    change_password: "/change_password",
    token_validity: "/token_validity",
    dashboard_count: "/dashboard_count",
    get_all_category: "/get_all_category",
    get_all_artist: "/get_all_artist",
    get_all_playlists: "/get_all_playlists",
    get_all_playlist_title: "/home-playlist/get-all",
    get_all_songs: "/get_all_songs",
    account_deletion_request: "/account_deletion_request",
    account_confirm_delete: "/account_confirm_delete",
    category: {
        list: "/category_list",
        create: "/category_create",
        update: "/category_update",
        delete: "/category_delete",
        deleteAll: "/category_delete_multiple"
    },
    artist: {
        list: "/artist_list",
        create: "/artist_create",
        update: "/artist_update",
        delete: "/artist_delete",
        deleteAll: "/artist_delete_multiple"
    },
    home_playlist: {
        list: "/home-playlist",
        create: "/home-playlist/create",
        update: "/home-playlist/update",
        delete: "/home-playlist/delete",
        deleteAll: "/home-playlist/delete-multiple"
    },
    song: {
        list: "/song_list",
        create: "/song_create",
        update: "/song_update",
        delete: "/song_delete",
        deleteAll: "/song_delete_multiple"
    },
    playlist: {
        list: "/playlist_list",
        create: "/playlist_create",
        update: "/playlist_update",
        delete: "/playlist_delete",
        deleteAll: "/playlist_delete_multiple"
    },
    banner: {
        list: "/banner",
        create: "/banner/create",
        update: "/banner/update",
        delete: "/banner/delete",
        deleteAll: "/banner/delete-multiple"
    },
    song_suration: {
        list: "/song-duration"
    },
    radio: {
        list: "/radio",
        create: "/radio/create",
        delete: "/radio/delete",
        moveup: "/radio/moveup",
        movedown: "/radio/movedown",
    },
    coupon: {
        list: "/coupon_list",
        create: "/coupon_create",
        update: "/coupon_update",
        delete: "/coupon_delete",
        deleteAll: "/coupon_delete_multiple",
        coupon_create_multiple: "/coupon_create_multiple",
        coupon_bulk_upload: "/coupon_bulk_upload"
    },
    external_video: {
        list: "/external-videos",
        create: "/external-videos/create",
        delete: "/external-videos/delete",
    },
    settings: {
        get: "/settings/get_maintenance_mode",
        update: "/settings/update_settings"
    },
};