import React, { useEffect, useState } from "react";
import { Form, Image } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { useHistory } from "react-router-dom";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";
import Select from "react-select";

const Create = () => {
    const { http } = AuthUser();
    const [categories, setCategories] = useState(null);
    const [artists, setArtists] = useState(null);
    const [trackTitle, setTrackTitle] = useState(null);
    const [category, setCategory] = useState(null);
    const [artist, setArtist] = useState(null);
    const [album, setAlbum] = useState("");
    const [recordingLabel, setRecordingLabel] = useState("");
    const [country, setCountry] = useState("");
    const [diskNumber, setDiskNumber] = useState("");
    const [trackNumber, setTrackNumber] = useState("");
    const [BPM, setBPM] = useState("");
    const [contractID, setContractID] = useState("");
    const [externalID, setExternalID] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [duration, setDuration] = useState("");
    const [lyricFile, setLyricFile] = useState(null);
    const [artworkCover, setArtworkCover] = useState(null);
    const [standardFile, setStandardFile] = useState(null);
    const [File360, setFile360] = useState(null);
    const [content, setContent] = useState("");
    const [status, setStatus] = useState("1");
    const [isTop, setIsTop] = useState("0");
    const [isExplicit, setIsExplicit] = useState("0");
    const [isVideo, setIsVideo] = useState("0");
    const [isDolby, setIsDolby] = useState("0");
    const [releaseDate, setReleaseDate] = useState("");    
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();

    useEffect(() => {
        http.get(Apis.get_all_category)
        .then(res => {
            setCategories(res.data.data);
        });
        http.get(Apis.get_all_artist)
        .then(res => {
            setArtists(res.data.data);
        });
    }, []);

    const loadImage = (e, imgId) => {
        const output = document.getElementById(imgId);
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = () => {
          URL.revokeObjectURL(output.src); // free memory
        }
        if (imgId === "img_thumb_preview")
            setArtworkCover(e.target.files[0]);
    }

    const loadAudio = (e, audId, audSrc) => {
        const audio = document.getElementById(audId);
        const source = document.getElementById(audSrc);
        source.src = URL.createObjectURL(e.target.files[0]);
        audio.pause();
        audio.load();
        source.onload = () => {
          URL.revokeObjectURL(source.src); // free memory
        }
        if (audId === "standard_file_preview")
            setStandardFile(e.target.files[0]);
        else if (audId === "360_file_preview")
            setFile360(e.target.files[0]);
    }

    const handleSave = (e) => {
        e.preventDefault();        
        setError(null);
        setLoading(true);

        const formData = new FormData();
        formData.append('name', trackTitle);
        for (let i = 0 ; i < category.length ; i++) {        
            formData.append('category[]', category[i].value);
        }
        if (artist) {
            for (let i = 0 ; i < artist.length ; i++) {
                formData.append('artist[]', artist[i].value);
            }
        }
        formData.append('album_name', album);
        formData.append('label', recordingLabel);
        formData.append('country', country);
        formData.append('disc_num', diskNumber);
        formData.append('track_num', trackNumber);
        formData.append('bpm', BPM);
        formData.append('contract_id', contractID);
        formData.append('external_id', externalID);
        formData.append('contact_email', contactEmail);
        formData.append('duration', duration);
        formData.append('lyricFile', lyricFile);
        formData.append('img_thumb', artworkCover);
        formData.append('song_file', standardFile);
        formData.append('xr_audio_file', File360);
        formData.append('content', content);
        formData.append('is_active', status);
        formData.append('is_top', isTop);
        formData.append('is_explicit', isExplicit);
        formData.append('is_video', isVideo);
        formData.append('is_dolby', isDolby);
        formData.append('release_date', releaseDate);        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        
        http.post(Apis.song.create, formData, config)
        .then(res => {
            setLoading(false);
            setSuccess("Song created successfully!");
            setTimeout(() => {
                history.goBack();
            }, 800);
        })
        .catch(err => {
            setLoading(false);
            if (err.response.status === 400) {
                const errorMsg = parseError(err.response.data.error);
                setError(errorMsg);
            } else {
                setError([err.response.data.message]);
            }
        });
    }

    return (
        <div className="container">
            <div className="row">          
                <div className="col-sm-6 mt-3">
                    <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> ADD MUSIC</h6>
                </div>
                <div className="col-sm-6 text-right mt-3">
                    
                </div>
            </div>
            {
              success && <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                    <strong>Success!</strong> {success}
                                </div>
                            </div>
                        </div>
            }
            {
                error && <ErrorMsg errors={error} />
            }
            <Form className="mt-4" onSubmit={ handleSave }>
                <div className="row">
                    <div className="col">
                        <Form.Group id="name" className="mb-4">
                            <Form.Label>Track Title<span style={{ color: "red" }}> *</span></Form.Label>
                            <Form.Control required onChange={(e) => setTrackTitle(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="category" className="mb-4">
                            <Form.Label>Category<span style={{ color: "red" }}> *</span></Form.Label>
                            <Select
                            required
                            isMulti
                            isSearchable
                            isClearable
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={ categories }
                            onChange={ setCategory } />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="artist" className="mb-4">
                            <Form.Label>Artist Name</Form.Label>
                            <Select
                            isMulti
                            isSearchable
                            isClearable
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={ artists }
                            onChange={ setArtist } />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Form.Group id="album" className="mb-4">
                            <Form.Label>Album Name</Form.Label>
                            <Form.Control onChange={(e) => setAlbum(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="recording_label" className="mb-4">
                            <Form.Label>Recording Label</Form.Label>
                            <Form.Control onChange={(e) => setRecordingLabel(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="country" className="mb-4">
                            <Form.Label>Country</Form.Label>
                            <Form.Control onChange={(e) => setCountry(e.target.value)} />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Form.Group id="disk_number" className="mb-4">
                            <Form.Label>Disk Number</Form.Label>
                            <Form.Control onChange={(e) => setDiskNumber(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="track_number" className="mb-4">
                            <Form.Label>Track Number</Form.Label>
                            <Form.Control onChange={(e) => setTrackNumber(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="bpm" className="mb-4">
                            <Form.Label>BPM</Form.Label>
                            <Form.Control onChange={(e) => setBPM(e.target.value)} />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Form.Group id="contract_id" className="mb-4">
                            <Form.Label>Contract ID</Form.Label>
                            <Form.Control onChange={(e) => setContractID(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="external_id" className="mb-4">
                            <Form.Label>External ID</Form.Label>
                            <Form.Control onChange={(e) => setExternalID(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="contact_email" className="mb-4">
                            <Form.Label>Contact Email</Form.Label>
                            <Form.Control onChange={(e) => setContactEmail(e.target.value)} />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Form.Group id="duration" className="mb-4">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control onChange={(e) => setDuration(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="lyric_file" className="mb-4">
                            <Form.Label>Lyric File</Form.Label>
                            <Form.Control type="file" onChange={(e) => setLyricFile(e.target.files[0])} />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">                    
                    <div className="col">
                        <Form.Group id="img_thumb" className="mb-4">
                            <Form.Label>Artwork Cover</Form.Label>
                            <div className="jumbotron" style={{minHeight: '300px'}}>
                                <Image id="img_thumb_preview" src="#" width="200" alt="Drag & drop files here …" />
                            </div>
                            <Form.Control type="file" accept="image/*" onChange={ (e) => loadImage(e, 'img_thumb_preview') } />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="standard_file" className="mb-4">
                            <Form.Label>Standard File</Form.Label>
                            <div className="jumbotron" style={{minHeight: '300px'}}>
                                <audio controls id="standard_file_preview">
                                    <source id="standard_file_preview_src" src="#" />
                                </audio>
                            </div>
                            <Form.Control type="file" accept="audio/*" onChange={ (e) => loadAudio(e, 'standard_file_preview', 'standard_file_preview_src') } />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="360_file" className="mb-4">
                            <Form.Label>360 File</Form.Label>
                            <div className="jumbotron" style={{minHeight: '300px'}}>
                                <audio controls id="360_file_preview">
                                    <source id="360_file_preview_src" src="#" />
                                </audio>
                            </div>
                            <Form.Control type="file" accept="audio/*" onChange={ (e) => loadAudio(e, '360_file_preview', '360_file_preview_src') } />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">                    
                    <div className="col">
                        <Form.Group id="content" className="mb-4">
                            <Form.Label>Content</Form.Label>
                            <Form.Control as="textarea" rows="15" onChange={(e) => setContent(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group id="is_360_audio" className="mb-4">
                            <Form.Label>Is 360 Audio</Form.Label><br />
                            <span style={{ cursor: 'pointer' }}>
                                <div className="d-inline-block me-1">OFF</div>
                                <div className="form-check form-switch d-inline-block">
                                    <input type="checkbox" className="form-check-input" id="is_360_audio_toggle" style={{ cursor: 'pointer' }} />
                                    <label htmlFor="is_360_audio_toggle" className="form-check-label">ON</label>
                                </div>
                            </span>
                        </Form.Group>
                        <Form.Group id="is_active" className="mb-4">
                            <Form.Label>Is Active</Form.Label><br />
                            <span style={{ cursor: 'pointer' }}>
                                <div className="d-inline-block me-1">OFF</div>
                                <div className="form-check form-switch d-inline-block">
                                    <input type="checkbox" className="form-check-input" id="is_active_toggle" defaultChecked={true} onChange={ (e) => setStatus(e.target.checked ? "1" : "0") } style={{ cursor: 'pointer' }} />
                                    <label htmlFor="is_active_toggle" className="form-check-label">ON</label>
                                </div>
                            </span>
                        </Form.Group>
                        <Form.Group id="is_top" className="mb-4">
                            <Form.Label>Is Top</Form.Label><br />
                            <span style={{ cursor: 'pointer' }}>
                                <div className="d-inline-block me-1">OFF</div>
                                <div className="form-check form-switch d-inline-block">
                                    <input type="checkbox" className="form-check-input" id="is_top_toggle" onChange={ (e) => setIsTop(e.target.checked ? "1" : "0") } style={{ cursor: 'pointer' }} />
                                    <label htmlFor="is_top_toggle" className="form-check-label">ON</label>
                                </div>
                            </span>
                        </Form.Group>
                        <Form.Group id="is_explicit" className="mb-4">
                            <Form.Label>Is Explicit</Form.Label><br />
                            <span style={{ cursor: 'pointer' }}>
                                <div className="d-inline-block me-1">OFF</div>
                                <div className="form-check form-switch d-inline-block">
                                    <input type="checkbox" className="form-check-input" id="is_explicit_toggle" onChange={ (e) => setIsExplicit(e.target.checked ? "1" : "0") } style={{ cursor: 'pointer' }} />
                                    <label htmlFor="is_explicit_toggle" className="form-check-label">ON</label>
                                </div>
                            </span>
                        </Form.Group>
                        <Form.Group id="is_video" className="mb-4">
                            <Form.Label>Is Video</Form.Label><br />
                            <span style={{ cursor: 'pointer' }}>
                                <div className="d-inline-block me-1">OFF</div>
                                <div className="form-check form-switch d-inline-block">
                                    <input type="checkbox" className="form-check-input" id="is_video_toggle" onChange={ (e) => setIsVideo(e.target.checked ? "1" : "0") } style={{ cursor: 'pointer' }} />
                                    <label htmlFor="is_video_toggle" className="form-check-label">ON</label>
                                </div>
                            </span>
                        </Form.Group>
                        <Form.Group id="is_dolby" className="mb-4">
                            <Form.Label>Is Dolby</Form.Label><br />
                            <span style={{ cursor: 'pointer' }}>
                                <div className="d-inline-block me-1">OFF</div>
                                <div className="form-check form-switch d-inline-block">
                                    <input type="checkbox" className="form-check-input" id="is_dolby_toggle" onChange={ (e) => setIsDolby(e.target.checked ? "1" : "0") } style={{ cursor: 'pointer' }} />
                                    <label htmlFor="is_dolby_toggle" className="form-check-label">ON</label>
                                </div>
                            </span>
                        </Form.Group>
                        <Form.Group id="release_date" className="mb-4">
                            <Form.Label>Release Date</Form.Label>
                            <Form.Control type="date" onChange={(e) => setReleaseDate(e.target.value)} />
                        </Form.Group>
                    </div>
                </div>

                <button className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontSize: '12px', fontWeight: 'bold' }} disabled={loading}>
                    CREATE {loading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                </button>
                <button type="button" className="btn btn-default ms-1" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} onClick={ () => history.goBack() }>CANCEL</button>
            </Form>
        </div>
    );
}
export default Create;