import React, { useEffect, useState } from "react";
import { Form, Image } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../../components/AuthUser";
import { Apis } from "../../common/Apis";
import { useHistory, useParams } from "react-router-dom";
import { parseError } from "../../common/Utility";
import ErrorMsg from "../../common/ErrorMsg";
import { useSelector } from "react-redux";
import Select from "react-select";


const Update = () => {
    let data = useSelector(state => state.listData);
    if (data.length === 0)
        data = JSON.parse(sessionStorage.getItem("listData"));
    const { id } = useParams();
    const dataSingle = data.filter(obj => obj.id === id);

    const { http } = AuthUser();
    const [name, setName] = useState(dataSingle[0].title);
    const [thumbImage, setThumbImage] = useState(dataSingle[0].image);
    const [bannerImage, setBannerImage] = useState(dataSingle[0].img_banner);
    const [status, setStatus] = useState(dataSingle[0].is_active);
    const [desp, setDesp] = useState(dataSingle[0].description);
    const [content, setContent] = useState(dataSingle[0].content);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const history = useHistory();
    const [music_playlists, setMusicPlayLists] = useState(null);
    const selectedCats = dataSingle[0].playlist ? { value: dataSingle[0].playlist.uuid, label: dataSingle[0].playlist.name } : null;
    const [music_playlist, setMusicPlayList] = useState(selectedCats);

    useEffect(() => {
        http.get(Apis.get_all_playlists)
        .then(res => {
            setMusicPlayLists(res.data.data);            
        });
    }, []);

    const loadImage = (e, imgId) => {
        const output = document.getElementById(imgId);
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = () => {
          URL.revokeObjectURL(output.src); // free memory
        }
        if (imgId === "img_thumb_preview")
            setThumbImage(e.target.files[0]);
        else if (imgId === "img_banner_preview")
            setBannerImage(e.target.files[0]);
    }

    const setStatusToState = (e) => {
        e.persist();
        setStatus(e.target.checked ? "1" : "0");
    }

    const deleteCategory = () => {
        const resp = window.confirm("Do you want to delete this Banner?");
        if (resp) {
          setDeleteError(null);
          setDeleteLoading(true);
          http.post(Apis.banner.delete, {banner_uuid: id})
          .then(res => {
            setDeleteLoading(false);
            setTimeout(() => {
              history.goBack();
            }, 500);
          })
          .catch(err => {
            setDeleteLoading(false);
            if (err.response.status === 400) {
              const errorMsg = parseError(err.response.data.error);
              setDeleteError(errorMsg);
            } else {
              setDeleteError([err.response.data.message]);
            }
          });
        }
      }

    const handleUpdate = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        const formData = new FormData();
        
        formData.append('banner_uuid', dataSingle[0].id)
        formData.append('image', thumbImage);
        formData.append('playlist_id', music_playlist.value);
        formData.append('is_active', status);
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        
        http.post(Apis.banner.update, formData, config)
        .then(res => {
            setLoading(false);
            setSuccess("Playlist Title updated successfully!");
            setTimeout(() => {
                history.goBack();
            }, 800);
        })
        .catch(err => {
            setLoading(false);
            if (err.response.status === 400) {
                const errorMsg = parseError(err.response.data.error);
                setError(errorMsg);
            } else {
                setError([err.response.data.message]);
            }
        });
    }

    return (
        <div className="container">
            <div className="row">          
                <div className="col-sm-6 mt-3">
                    <h6 style={{ fontWeight: 'bold' }}><span><FontAwesomeIcon icon={ faList } /></span> PLAYLIST TITLE <span style={{ fontSize: '12px', opacity: 0.5 }}>Update</span></h6>
                </div>
                <div className="col-sm-6 text-right mt-3">
                    
                </div>
            </div>
            {
              success && <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                    <strong>Success!</strong> {success}
                                </div>
                            </div>
                        </div>
            }
            {
                error && <ErrorMsg errors={error} />
            }
            {
                deleteError && <ErrorMsg errors={deleteError} />
            }
            <Form className="mt-4" onSubmit={ handleUpdate }>               
                <Form.Group id="img_thumb" className="mb-4">
                    <Form.Label>Image File</Form.Label>
                    <div className="jumbotron">
                        <Image id="img_thumb_preview" src={ thumbImage } width="200" alt="Drag & drop files here …" />
                    </div>
                    <Form.Control type="file" accept="image/*" onChange={ (e) => loadImage(e, 'img_thumb_preview') } />
                </Form.Group>

                <Form.Group id="category" className="mb-4">
                    <Form.Label>Playlist<span style={{ color: "red" }}> *</span></Form.Label>
                    <Select
                    required
                    isSearchable
                    isClearable
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={music_playlist}
                    options={ music_playlists }
                    onChange={ setMusicPlayList } />
                </Form.Group>

                <Form.Group id="is_active" className="mb-4">
                    <Form.Label>Is Active</Form.Label><br />
                    <span style={{ cursor: 'pointer' }}>
                        <div className="d-inline-block me-1">OFF</div>
                        <div className="form-check form-switch d-inline-block">
                            <input type="checkbox" className="form-check-input" id="is_active_toggle" defaultChecked={ status } onChange={ setStatusToState } style={{ cursor: 'pointer' }} />
                            <label htmlFor="is_active_toggle" className="form-check-label">ON</label>
                        </div>
                    </span>
                </Form.Group>
                
                <button className="btn cust-button" style={{ backgroundColor: '#36c6d3', borderColor: '#2bb8c4', color: '#fff', fontSize: '12px', fontWeight: 'bold' }} disabled={loading}>
                    UPDATE {loading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                </button>
                <button type="button" className="btn btn-danger ms-1" style={{ fontSize: '12px', fontWeight: 'bold' }} onClick={ deleteCategory }>
                    DELETE {deleteLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                </button>
                <button type="button" className="btn btn-default ms-1" style={{ backgroundColor: '#eee', border: '1px solid #ccc', fontSize: '12px', fontWeight: 'bold' }} onClick={ () => history.goBack() }>CANCEL</button>
            </Form>
        </div>
    );
}
export default Update;